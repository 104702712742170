<div class="w-100 h-100">
  <div class="theme-overlay-container">
    <div class="theme-overlay-header">
      <span [ngSwitch]="plug?.name">
        <span [translate]="'settings.plugins.azure_integration'" *ngSwitchCase="'Azure'"></span>
        <span [translate]="'settings.plugins.youtrack_integration'" *ngSwitchCase="'Youtrack'"></span>
      </span>
      <button class="text-t-secondary"
              type="button"
              [matTooltip]="'hint.message.common.close' | translate"
              mat-dialog-close
              class="close">
      </button>
    </div>

    <div class="theme-overlay-content">
      <form class="ts-form pt-30" [formGroup]="updateForm">
        <div class="form-group ts-form" >
          <label  class="control-label required">
            <span [translate]="'settings.plugins.organization_url'"></span>
          </label>
          <input type="text"
                 formControlName="url"
                 class="form-control "  required>
          <span *ngIf="!updateForm.get('url').valid && updateForm.get('url').touched" class="help-block" [translate]="'plugins.message.url'">
          </span>
        </div>
        <div class="form-group ts-form" >
          <label for="accessKey" class="control-label required">
            <span [translate]="'settings.plugins.access_key'"></span>
          </label>
          <input type="password"
                 formControlName="token"
                 id="accessKey" class="form-control "  required>
          <span *ngIf="!updateForm.get('token').valid && updateForm.get('token').touched" class="help-block" [translate]="'plugins.message.token'">
          </span>
        </div>
      </form>
    </div>

    <div class="theme-overlay-footer">
      <button
        type="button"
        mat-dialog-close
        class="theme-btn-clear-default" [translate]="'btn.common.cancel'">
      </button>
      <button type="submit" [disabled]="!updateForm.valid || saving"
              class="theme-btn-primary" (click)="onSubmit()" [translate]="saving ?  'message.common.saving' : 'btn.common.create'"></button>
    </div>
  </div>
</div>
