<div class="border-active-t-5 pb-35 pt-30 px-35">
  <div class="fz-16 rb-regular mb-10 text-github-blk1">Thank you for trying <b>Testsigma</b> Open-source. In case you missed it…</div>
  <div class="rb-semi-bold w-488 fz-32 mb-10 line-height-45 text-github-blk2-g">We’re an open-source, community-powered platform.</div>
  <div class="rb-regular mb-20 fz-20 w-488 line-height-28 text-github-blk1">Help us reshape the future of software testing by starring the Testsigma repo on GitHub.</div>
  <div class="w-488 mb-20 text-github-bg-blue">
    <div class="position-relative d-flex">
      <img class="ml-auto octocat"/>
      <span  class="position-absolute w-285 line-height-28 fz-18 top-40p pl-20">Connect, Create and Contribute. Join all the fun on <b>GitHub</b></span>
    </div>
  </div>
  <div class="d-flex">
    <a class="rb-regular ml-auto my-auto line-height-28 fz-18 ml-25 pr-25 text-github-grey pointer" (click)="skip()">Skip for now</a>
    <a href="https://github.com/testsigmahq/testsigma">
      <button class="theme-btn-primary p-25 border-rds-6">
        <i class="fa-star fz-20"></i>
        <span class="rb-regular fz-20 pl-10" (click)="star()">Star us on GitHub</span>
      </button>
    </a>
  </div>
</div>
<style>

</style>
