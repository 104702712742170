<div
  *ngIf="authGuard.session.user"
  class="bg-white border-rds-4 global-love-modal ng-scope p-25 shadow-all2-b4">
  <div class="pl-25">
    <span [innerHTML]="'dashboard.testsigma.love_message' | translate: {heart: '&#10084;&#65039;'}" ></span>
  </div>
  <div class="d-flex flex-row justify-content-around py-15">
    <div class="custom-div-button ts-col-45 border-rds-6" (click)="openGithub()">
      <div class="d-flex flex-row">
        <div class="github-image"></div>
        <div class="align-self-center rb-medium" [translate]="'dashboard.start_us_on_github'"></div>
      </div>
    </div>
    <div class="custom-div-button ts-col-45 border-rds-6" (click)="openTwitter()">
      <div class="d-flex flex-row">
        <div class="twitter-image"></div>
        <div class="align-self-center rb-medium" [translate]="'dashboard.tweet_us'"></div>
      </div>
    </div>
  </div>
</div>
