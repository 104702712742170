/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

export enum TestPlanLabType {
  TestsigmaLab = "TestsigmaLab",
  Hybrid = "Hybrid",
  PrivateGrid = "PrivateGrid"
}
