<div class="ts-form mt-20" *ngIf="!spaces">
  <app-placeholder-loader [isDetails]="true" ></app-placeholder-loader>
</div>
<form *ngIf="spaces"
  (ngSubmit)="onSubmit()"
  class="ts-form h-100 form-field-bg" >
  <div
    class=" overflow-x-hidden p-30 theme-only-items-scroll sm-h" >
    <div class="d-flex">
      <label
        class="ts-col-50 rb-medium text-t-highlight fz-18"
        [translate]="isLinkToIssue ? 'test_case_result.details.report_link_bug.title' : 'test_case_result.details.report_create_bug.title'"
      ></label>
      <button
        type="button"
        (click)="toggleLinkToIssue()"
        [translate]="isLinkToIssue ?  'test_case_result.details.btn.create_new' : 'test_case_result.details.btn.link_to_existing'"
        class="theme-btn-clear-default ml-auto"></button>
    </div>

    <div class="form-group pb-20 d-flex flex-wrap">
      <label
        class="custom-label text-t-highlight required ts-col-100"
        [translate]="'test_case_result.details.team'"></label>
      <div class="dropdown section-title ts-col-50">
        <div
          [class.showField]="isTeamShow"
          class="btn-group text-nowrap field">
          <a
            (click)="toggleDropdown('isTeamShow');isSpaceShow = false; isFolderShow=false; isListShow=false"
            class="rb-medium d-flex align-items-center theme-border-y theme-border-x p-10 border-rds-4"
            [textContent]="selectedTeam.name">
          </a>
        </div>
        <div
          *ngIf="isTeamShow"
          class="dropdown-menu d-block drop-down-transparent">
          <ul
            class="bg-white border-rds-4 ng-scope pl-0 shadow-all2-b4 m-0">
            <li
              class="p-10 rb-medium grey-on-hover text-dark d-flex align-items-center"
              *ngFor="let team of teams"
              (click)="setSelectedItem('selectedTeam', team, 'isTeamShow');"
              [textContent]="team.name">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="form-group pb-20 d-flex flex-wrap">
      <label
        class="custom-label text-t-highlight required ts-col-100"
        [translate]="'test_case_result.details.report_bug.space'"></label>
      <div class="dropdown section-title ts-col-50">
        <div
          [class.showField]="isSpaceShow"
          class="btn-group text-nowrap field">
          <a (click)="toggleDropdown('isSpaceShow');isTeamShow = false; isFolderShow=false; isListShow=false"
            class="rb-medium d-flex align-items-center theme-border-y theme-border-x p-10 border-rds-4"
            [textContent]="selectedSpace.name">
          </a>
        </div>
        <div
          *ngIf="isSpaceShow"
          class="dropdown-menu d-block drop-down-transparent">
          <ul
            class="bg-white border-rds-4 ng-scope pl-0 shadow-all2-b4 m-0">
            <li
              class="p-10 rb-medium grey-on-hover text-dark d-flex align-items-center"
              *ngFor="let space of spaces"
              (click)="setSelectedItem('selectedSpace', space, 'isSpaceShow')"
              [textContent]="space.name">
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="form-group pb-20 d-flex flex-wrap" *ngIf="!isNoFolders">
      <label
        class="custom-label text-t-highlight required ts-col-100"
        [translate]="'test_case_result.details.report_bug.folder'"></label>
      <div class="dropdown section-title ts-col-50">
        <div
          [class.showField]="isFolderShow"
          class="btn-group text-nowrap field">
          <a (click)="toggleDropdown('isFolderShow');isTeamShow = false; isSpaceShow=false; isListShow=false"
             class="rb-medium d-flex align-items-center theme-border-y theme-border-x p-10 border-rds-4"
             [textContent]="selectedFolder?.name">
          </a>
        </div>
        <div
          *ngIf="isFolderShow"
          class="dropdown-menu d-block drop-down-transparent">
          <ul
            class="bg-white border-rds-4 ng-scope pl-0 shadow-all2-b4 m-0">
            <li
              class="p-10 rb-medium grey-on-hover text-dark d-flex align-items-center"
              *ngFor="let folder of folders"
              (click)="setSelectedItem('selectedFolder', folder, 'isFolderShow')"
              [textContent]="folder.name">
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="warning-tip-label sm-z"
      [translate]="'test_case_result.details.report_bug.no_folders_warning'" *ngIf="isNoFolders">
    </div>

    <div class="form-group pb-20 d-flex flex-wrap" *ngIf="!isNoFolders">
      <label
        class="custom-label text-t-highlight required ts-col-100"
        [translate]="'test_case_result.details.report_bug.list'"></label>
      <div class="dropdown section-title ts-col-50">
        <div
          [class.showField]="isListShow"
          class="btn-group text-nowrap field">
          <a (click)="toggleDropdown('isListShow');isTeamShow = false; isSpaceShow=false; isFolderShow=false"
             class="rb-medium d-flex align-items-center theme-border-y theme-border-x p-10 border-rds-4"
             [textContent]="selectedList?.name">
          </a>
        </div>
        <div
          *ngIf="isListShow"
          class="dropdown-menu d-block drop-down-transparent">
          <ul
            class="bg-white border-rds-4 ng-scope pl-0 shadow-all2-b4 m-0">
            <li
              class="p-10 rb-medium grey-on-hover text-dark d-flex align-items-center"
              *ngFor="let list of lists"
              (click)="setSelectedItem('selectedList', list, 'isListShow')"
              [textContent]="list.name">
            </li>
          </ul>
        </div>
      </div>
    </div>

    <mat-form-field
      *ngIf="isLinkToIssue"
      class="w-50 custom-mat-auto-complete">
      <input
        matInput
        [placeholder]="'test_case_result.details.report_bug.search_issue_placeholder' | translate"
        aria-label="State"
        [formControl]="searchIssuesFormCtrl"
        [matAutocomplete]="issues">
      <mat-autocomplete #issues="matAutocomplete">
        <mat-option *ngIf="isFetchingIssues">
          <span [translate]="'message.common.loading'"></span>
          <span class="loading-dots"></span>
        </mat-option>
        <mat-option *ngIf="!issueList.length && !isFetchingIssues">
          <span [translate]="searchIssuesFormCtrl.value ?'search.notfound':'plugins.message.no_previous_issues'"></span>
        </mat-option>
        <mat-option
          *ngFor="let issue of issueList" [value]="issue.name"
          (onSelectionChange)="setSelectedItem('selectedIssue', issue)">
          <span [textContent]="issue.name"></span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div
      *ngIf="!isLinkToIssue && formFR"
      [formGroup]="formFR" class="form-group pb-20">
      <label
        class="custom-label text-t-highlight required"
        for="title" [translate]="'runs.list_view.title'"></label>
      <input
        class="form-control field"
        name="title"
        [formControlName]="['title']"
        [(ngModel)]="title"
        id="title" type="text">
    </div>
    <div
      *ngIf="!isLinkToIssue && formFR"
      [formGroup]="formFR" class="form-group pb-20">
      <label
        class="custom-label text-t-highlight required"
        for="description" [translate]="'test_case_result.details.description'"></label>
      <textarea
        class="form-control field"
        name="description"
        [formControlName]="['description']"
        [(ngModel)]="description"
        id="description" rows="4"></textarea>
    </div>
  </div>

  <div class="form-row py-10 px-30 text-right">
    <button
      class="theme-btn-clear-default"
      mat-dialog-close
      type="button"
      [translate]="'btn.common.cancel'"></button>
    <button
      class="theme-btn-primary"
      type="submit"
      [translate]="!isLinkToIssue ? 'btn.common.save' : 'test_case_result.details.btn.link_to_existing'"
      [disabled]="(!isLinkToIssue ? !(description && title) : !selectedIssue) || isButtonClicked"></button>
  </div>
</form>
