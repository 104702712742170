<div class="w-100 h-100">
  <div class="theme-overlay-container">
    <div class="theme-overlay-header">
      <span class="theme-overlay-title" [translate]="'settings.plugins.jira_integration'" ></span>
      <button class="text-t-secondary"
              type="button"
              [matTooltip]="'hint.message.common.close' | translate"
              mat-dialog-close
              class="close">
      </button>
    </div>

    <div class="theme-overlay-content">
      <form class="ts-form pt-30" [formGroup]="updateForm">
        <div class="form-group ts-form" >
          <label for="name" class="control-label required">
            <span [translate]="'settings.plugins.url'"></span>
          </label>
          <input
            type="text"
            formControlName="url"
            id="Name" class="form-control">
          <span *ngIf="!updateForm.get('url').valid && updateForm.get('url').touched" class="help-block"
          [translate]="'plugins.message.url'">
          </span>
        </div>
        <div class="form-group ts-form" >
          <label for="username" class="control-label required">
            <span [translate]="'settings.plugins.username'"></span>
          </label>
          <input type="text"
                 formControlName="username"
                 id="username" class="form-control " >
          <span *ngIf="!updateForm.get('username').valid && updateForm.get('username').touched" class="help-block"
          [translate]="'plugins.message.username'">
          </span>
        </div>

        <div class="form-group ts-form" >
          <label for="accessKey" class="control-label required">
            <span [translate]="'settings.plugins.token'"></span>
          </label>
          <input type="password"
                 formControlName="token"
                 id="accessKey" class="form-control "  >
          <span *ngIf="!updateForm.get('token').valid && updateForm.get('token').touched" class="help-block"
          [translate]="'plugins.message.token'">
          </span>
        </div>

        <div class="form-group ts-form" >
          <label for="confirmToken" class="control-label required">
            <span [translate]="'settings.plugins.confirm_token'"></span>
          </label>
          <input type="password"
                 formControlName="confirmToken"
                 id="confirmToken" class="form-control "  >
          <span *ngIf="updateForm.get('confirmToken').value !== updateForm.get('token').value && updateForm.get('confirmToken').touched" class="help-block"
                [translate]="'plugins.message.confirmToken'">
          </span>
        </div>
      </form>
    </div>

    <div class="theme-overlay-footer">
      <button
        type="button"
        mat-dialog-close
        class="theme-btn-clear-default" [translate]="'btn.common.cancel'">
      </button>
      <button type="submit" [disabled]="!updateForm.valid || updateForm.get('confirmToken').value !== updateForm.get('token').value"
              class="theme-btn-primary" (click)="onSubmit()"
              [translate]="saving? 'message.common.saving' : 'btn.common.create'"></button>
    </div>
  </div>
</div>
