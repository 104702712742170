<div class="w-100 h-100">
  <div class="theme-overlay-container">
    <div class="theme-overlay-header">
      <span class="theme-overlay-title" [translate]="'settings.storage.aws'" ></span>
      <button
              type="button"
              [matTooltip]="'hint.message.common.close' | translate"
              mat-dialog-close
              class="close">
      </button>
    </div>


    <div class="theme-overlay-content">
      <form class="ts-form pt-30" [formGroup]="updateForm">
        <div class="form-group ts-form" >
          <label for="bucketName" class="control-label required">
            <span [translate]="'settings.storage.aws.bucket_name'"></span>
          </label>
          <input
            type="text"
            formControlName="bucketName"
            id="bucketName" class="form-control">
          <span *ngIf="!updateForm.get('bucketName').valid && updateForm.get('bucketName').touched" class="help-block"
                [translate]="'settings.message.aws_bucket_name'">
          </span>
        </div>

        <div class="form-group ts-form" >
          <label for="endpoint" class="control-label required">
            <span [translate]="'settings.storage.aws.endpoint'"></span>
          </label>
          <input type="text"
                 formControlName="endpoint"
                 id="endpoint" class="form-control " >
          <span *ngIf="!updateForm.get('endpoint').valid && updateForm.get('endpoint').touched" class="help-block"
                [translate]="'settings.message.aws_endpoint'">
          </span>
        </div>

        <div class="form-group ts-form" >
          <label for="region" class="control-label required">
            <span [translate]="'settings.storage.aws.region'"></span>
          </label>
          <input type="text"
                 formControlName="region"
                 id="region" class="form-control "  >
          <span *ngIf="!updateForm.get('region').valid && updateForm.get('region').touched" class="help-block"
                [translate]="'settings.message.aws_region'">
          </span>
        </div>

        <div class="form-group ts-form" >
          <label for="accessKey" class="control-label required">
            <span [translate]="'settings.storage.aws.access_key'"></span>
          </label>
          <input type="text"
                 formControlName="accessKey"
                 id="acessKey" class="form-control "  >
          <span *ngIf="!updateForm.get('accessKey').valid && updateForm.get('accessKey').touched" class="help-block"
                [translate]="'settings.message.aws_access_key'">
          </span>
        </div>

        <div class="form-group ts-form" >
          <label for="secretKey" class="control-label required">
            <span [translate]="'settings.storage.aws.secret_key'"></span>
          </label>
          <input type="password"
                 formControlName="secretKey"
                 id="secretKey" class="form-control">
          <span *ngIf="!updateForm.get('secretKey').valid && updateForm.get('secretKey').touched" class="help-block"
                [translate]="'settings.message.aws_secret_key'">
          </span>
        </div>

      </form>
    </div>

    <div class="theme-overlay-footer">
      <button
        type="button"
        mat-dialog-close
        class="theme-btn-clear-default" [translate]="'btn.common.cancel'">
      </button>
      <button type="submit" [disabled]="!updateForm.valid"
              class="theme-btn-primary" (click)="onSubmit()"
              [translate]="saving? 'message.common.saving' : 'btn.common.create'"></button>
    </div>
  </div>
</div>
