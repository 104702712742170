export enum HttpHeaderNames {
  'Accept' = 'Accept',
  'Accept-Charset' = 'Accept-Charset',
  'Accept-Encoding' = 'Accept-Encoding',
  'Accept-Language' = 'Accept-Language',
  'Access-Control-Request-Headers' = 'Access-Control-Request-Headers',
  'Access-Control-Request-Method' = 'Access-Control-Request-Method',
  'Authorization' = 'Authorization',
  'Cache-Control' = 'Cache-Control',
  'Content-MD5' = 'Content-MD5',
  'Content-Length' = 'Content-Length',
  'Content-Transfer-Encoding' = 'Content-Transfer-Encoding',
  'Content-Type' = 'Content-Type',
  'Cookie' = 'Cookie',
  'Date' = 'Date',
  'Expect' = 'Expect',
  'From' = 'From',
  'Host' = 'Host',
  'If-Match' = 'If-Match',
  'If-Modified-Since' = 'If-Modified-Since',
  'If-None-Match' = 'If-None-Match',
  'If-Unmodified-Since' = 'If-Unmodified-Since',
  'If-Range' = 'If-Range',
  'Keep-Alive' = 'Keep-Alive',
  'Max-Forwards' = 'Max-Forwards',
  'Origin' = 'Origin',
  'Pragma' = 'Pragma',
  'Proxy-Authorization' = 'Proxy-Authorization',
  'Range' = 'Range',
  'Referer' = 'Referer',
  'TE' = 'TE',
  'Trailer' = 'Trailer',
  'Transfer-Encoding' = 'Transfer-Encoding',
  'Upgrade' = 'Upgrade',
  'User-Agent' = 'User-Agent',
  'Via' = 'Via',
  'Warning' = 'Warning',
  'X-Requested-With' = 'X-Requested-With',
  'X-Do-Not-Track' = 'X-Do-Not-Track',
  'DNT' = 'DNT',
  'x-api-key' = 'x-api-key',
  'Connection' = 'Connection'
}
