<div class="ts-col-100 h-100 justify-content-center align-items-center d-flex onboarding-container">

  <div class="login-container">

    <div class="ts-col-60 d-flex flex-column my-auto px-20 py-50 pr-60">
      <div class="flex-column d-flex justify-content-center text-center mb-30">
        <div class="text-uppercase rb-medium" [translate]="'login_form.be_a_part_of'"></div>
        <div class="fz-32 rb-medium" [translate]="'login_form.testsigma_community'"></div>
      </div>
      <div class="d-flex justify-content-between mb-20">
        <div class="text-center ts-col-30">
          <div class="connect_image"></div>
          <div [translate]="'login_form.connect'" class="text-uppercase rb-medium my-7"></div>
          <div [translate]="'login_form.connect_sub_message'" class="fz-12"></div>
        </div>
        <div class="text-center ts-col-30">
          <div class="create_image"></div>
          <div [translate]="'login_form.create'" class="text-uppercase rb-medium my-7"></div>
          <div [translate]="'login_form.create_sub_message'" class="fz-12"></div>
        </div>
        <div class="text-center ts-col-30">
          <div class="contribute_image"></div>
          <div [translate]="'login_form.contribute'" class="text-uppercase rb-medium my-7"></div>
          <div [translate]="'login_form.contribute_sub_message'" class="fz-12"></div>
        </div>
      </div>
      <div class="position-relative">
        <div class="join_community pl-40"></div>
        <div class="d-flex flex-column ml-auto ts-col-50 mr-40 position-absolute px-40 vertical-center right-10">
          <div class="fz-16 mb-10 rb-medium ts-col-100 ml-n20" [translate]="'login_form.join_community_msg'"></div>
          <a target="_blank" href="https://discord.com/invite/5caWS7R6QX" class="border-0 btn btn-clear-default fz-16 py-10 theme-btn-clear-default ts-col-100 ml-n20" [translate]="'login_form.join_community'"></a>
        </div>
      </div>
    </div>
    <div class="ts-col-40 d-flex flex-column my-50 pl-60 pr-20 border-lightGray-l-1">
      <div class="d-flex text-center">
        <span class="testsigma-logo-with-title"></span>
      </div>
      <div class="login-label rb-medium mb-35 fz-20 pt-30"
           [class.pt-50]="isGoogleAuth"
           [translate]="'login_form.sign_in_to_testsigma'"></div>
      <form
        method="POST"
        class="ts-form" autocomplete="off" action="/login" #loginFormElm
        [formGroup]="loginForm">
        <div *ngIf="!isGoogleAuth" class="mb-20">
          <div class="form-group pb-18 mt-n25" style="position: absolute!important;" *ngIf="badCredentials">
            <label [translate]="'login_form.bad_credentials'" class="text-danger"></label>
          </div>
          <div class="form-group pb-18 mt-n25" style="position: absolute!important;" *ngIf="invalidAuthentication">
            <label [translate]="'login_form.user_not_authenticated'" class="text-danger"></label>
          </div>
          <div class="form-group pb-18">
            <label
              class="login-label"
              [translate]="'login_form.username'"></label>
            <input
              class="form-control"
              [formControlName]="['username']"
              type="email" name="username" autofocus autocomplete="false" id="loginUsername">
          </div>
          <div class="form-group pb-18">
            <label
              class="login-label"
              [translate]="'login_form.password'"></label>
            <input
              class="form-control"
              [formControlName]="['password']"
              type="password" name="password" autocomplete="false">
          </div>
          <div class="form-group d-flex align-items-center">
            <div class="d-flex align-items-center">
              <mat-checkbox
                id="remember_me"
                class="mat-checkbox">
                <span class="signed_in pl-7" [translate]="'login_form.keep_me_logged_in'"></span>
              </mat-checkbox>
            </div>
          </div>

          <button
            [disabled]="loginForm?.invalid"
            [translate]="inTransit ? 'btn.common.please_wait': 'login_form.sign_in'"
            (click)="inTransit=true;login();"
            class="login-primary-btn" type="submit"></button>
        </div>
        <div class="mt-auto mb-20" *ngIf="isGoogleAuth">
          <button
            class="login-google-btn"
            onclick="window.location.href='/oauth2/authorization/google'">
            <span class="login-google-logo"></span>
            <span [translate]="'login_form.sign_in_with_google'"></span>
          </button>
        </div>
        <div class="bottom-0 mt-auto position-absolute">
          <div class="mb-15">Trouble signing in? Contact support on <a target="_blank" class="text-link" href="https://discord.com/invite/5caWS7R6QX">Testsigma Discord</a></div>
          <div class="mt-5 mat-hint">Copyright &copy;  2021 Testsigma Technologies Inc. All Rights Reserved</div>
        </div>
      </form>
    </div>
  </div>
</div>
