<form
  class="ts-form ts-col-100">
  <div
    [translate]="'test_plans.form.settings.title'"
    class="fz-26 rb-light ts-col-100 text-center my-8 theme-border-b pb-15"></div>
  <!--  settings-->
  <app-test-plan-time-out-settings
    *ngIf="testPlan"
    [formGroup]="formGroup"
    [environmentId]="testPlan?.environmentId"
    [version]="version"></app-test-plan-time-out-settings>
  <!-- settings-->
  <app-test-plan-recovery-actions
    [formGroup]="formGroup"></app-test-plan-recovery-actions>
</form>
