/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

export enum ElementCreateType {
  MANUAL = "MANUAL",
  CHROME = "CHROME",
  MOBILE_INSPECTOR = "MOBILE_INSPECTOR"
}
