/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

export enum RecoverAction {
  Run_Next_Testcase = "Run_Next_Testcase",
  Run_Next_Step = "Run_Next_Step"
}
