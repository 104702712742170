/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

export enum Screenshot {
  NONE= "NONE",
  ALL_TYPES = "ALL_TYPES",
  FAILED_STEPS = "FAILED_STEPS"
}
