<div class="d-flex flex-row position-relative h-100">
  <div
    [class.ts-col-30]="!isFullModeScreen"
    [class.ts-col-60]="isFullModeScreen">
    <div class="align-item-center d-flex px-25 py-15 theme-border-b">
      <div class="rb-medium fz-14">
        <i (click)="backToListView()"
           [class.d-none]="!isMultipleRecorder"
           [class.visibility-hidden]="!(saveElementsComponent?.elements?.length>0 && saveElementsComponent?.element)"
           [matTooltip]="(element ? 'agents.mobile.inspection.go_back_to_list':'agents.mobile.inspection.go_back_to_form' )| translate"
           class="fa-back-arrow pr-8 text-dark">
        </i>
        <span
          [translate]="element ? 'agents.mobile.inspection.element_form': isMultipleRecorder ? 'agents.mobile.inspection.record_title_Multiple' : 'agents.mobile.inspection.record_title'"
          class="text-secondary"
        ></span>
      </div>
      <div class="ml-auto">
        <span
          class="ml-auto pl-14 pointer"
          [matTooltip]="(pauseRecord?'mobile_recorder.hint.resume_recording':'mobile_recorder.hint.pause_recording')|translate"
          (click)="pauseRecord=!pauseRecord">
     <span
       [class.fa-pause-record]="!pauseRecord"
       [class.fa-play-circle]="pauseRecord"
       [class.result-status-text-1]="!pauseRecord"
       [class.result-status-text-0]="pauseRecord"
       class="mr-5 pulse">
          </span>
          <span [translate]="!pauseRecord ? 'agents.mobile.inspection.recording' : 'agents.mobile.inspection.pause'"
                class="text-danger"></span>
        </span>
      </div>
    </div>
    <div class="h-100-50">
      <div
        [class.h-100]="!isFullModeScreen"
        [class.h-50]="isFullModeScreen">
        <ng-container *ngTemplateOutlet="saveElementsTemplate"></ng-container>
      </div>
      <div *ngIf="!mirroring && isFullModeScreen"
           class="inspection-details-section h-50">
        <mat-tab-group
          [class.disabled]="loading"
          [(selectedIndex)]="selectedIndex"
          class="inspector-mat-tab w-100 border-0 pr-15 show-close pt-10 inline-tabs"
          mat-stretch-tabs>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fa-app-source"></i>
              <span [translate]="'agents.mobile.inspection.app_source'"></span>
            </ng-template>
            <app-source
              *ngIf="mirroringContainerComponent"
              (highlightCanvas)="mirroringContainerComponent.highlightOnSelection($event)"
              (highlightCanvasOnHover)="mirroringContainerComponent.mouseInFromAppSource($event)"
              (mouseOutFromAppSource)="mirroringContainerComponent.mouseOutFromAppSource()"
              [dataSource]="dataSource"
              [inspectedElement]="mirroringContainerComponent.inspectedElement"
              [isNativeAppEnabled]="mirroringContainerComponent.isNativeAppEnabled"
              [loading]="loading">
            </app-source>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fa-mirroring-selection"></i>
              <span [translate]="'agents.mobile.inspection.selected_element'"></span>
            </ng-template>
            <app-selected-elements
              *ngIf="mirroringContainerComponent"
              [currentPageSource]="currentPageSource"
              [inspectedElement]="mirroringContainerComponent.inspectedElement"
              [isNativeAppEnabled]="mirroringContainerComponent.isNativeAppEnabled"
              [sessionId]="sessionId"
              [devicesService]="devicesService"
              [optimisingXpath]="mirroringContainerComponent.optimisingXpath">
            </app-selected-elements>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div *ngIf="!mirroring && !isFullModeScreen" [class.ts-col-40]="fullScreenMode"
       class="inspection-details-section ts-col-30 h-100">
    <mat-tab-group [class.disabled]="loading" [class.show-close]="fullScreenMode"
                   [(selectedIndex)]="selectedIndex"
                   class="inspector-mat-tab w-100 border-0 pt-10 pr-15"
                   style="height: 100%!important;"
                   mat-stretch-tabs>
      <mat-tab>
        <ng-template mat-tab-label>
          <span [translate]="'agents.mobile.inspection.app_source'"></span>
        </ng-template>
        <app-source *ngIf="mirroringContainerComponent"
                    (highlightCanvas)="mirroringContainerComponent.highlightOnSelection($event)"
                    (highlightCanvasOnHover)="mirroringContainerComponent.mouseInFromAppSource($event)"
                    (mouseOutFromAppSource)="mirroringContainerComponent.mouseOutFromAppSource()"
                    [dataSource]="dataSource"
                    [inspectedElement]="mirroringContainerComponent.inspectedElement"
                    [isNativeAppEnabled]="mirroringContainerComponent.isNativeAppEnabled"
                    [loading]="loading">
        </app-source>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span [translate]="'agents.mobile.inspection.selected_element'"></span>
        </ng-template>
        <app-selected-elements *ngIf="mirroringContainerComponent"
                               [currentPageSource]="currentPageSource"
                               [inspectedElement]="mirroringContainerComponent.inspectedElement"
                               [isNativeAppEnabled]="mirroringContainerComponent.isNativeAppEnabled"
                               [sessionId]="sessionId"
                               [devicesService]="devicesService"
                               [optimisingXpath]="mirroringContainerComponent.optimisingXpath">
        </app-selected-elements>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div  class="mirroring-section ts-col-40" style="background: #CEDAE6">
    <div class="d-flex ml-auto pt-10 pr-10">
      <button *ngIf="!loading && isMultipleRecorder"
              (click)="loading?'':saveAndQuit()"
              [style.right.px]="quitButton.clientWidth+10"
              [disabled]="deleteClicked||!saveElementsComponent?.inspectedElement"
              [matTooltip]="(loading?'agents.mobile.inspection.quit_session_later':
          (saveElementsComponent?.formSubmitted? 'agents.mobile.inspection.save_n_quit':'agents.mobile.inspection.quit_session'))| translate"
              class="theme-btn-primary ml-auto">
        <span class="fz-14 pr-4" [translate]="'agents.mobile.inspection.save_n_quit'"></span> &times;
      </button>

      <button (click)="loading?'':openUnsavedWorkWarning()"
              #quitButton
              [class.ml-auto]="!isMultipleRecorder || loading"
              [disabled]="deleteClicked"
              [matTooltip]="(loading?'agents.mobile.inspection.quit_session_later':'agents.mobile.inspection.quit_session')| translate"
              class="theme-btn-clear-default bg-white">
        <span class="fz-14 pr-4" [translate]="'agents.mobile.inspection.quit_session'"></span> &times;
      </button>
    </div>
  <app-mirroring-container #mirroringContainerComponent
                           [(dataSource)]="dataSource"
                           [(loading)]="loading"
                           [loadingActions]="loadingActions"
                           [currentPageSource]="currentPageSource"
                           [isIosNative]="isIosNative()"
                           [isEdit]="isEdit"
                           [uiId]="uiId"
                           [sessionId]="sessionId"
                           [data]="data"
                           [isPauseRecord]="pauseRecord"
                           [mirroring]="mirroring"
                           [selectedElement]="selectedElement1"
                           class="d-flex h-100-40 ts-col-100"
  ></app-mirroring-container>
  </div>
</div>
<!--<simple-notifications></simple-notifications>-->
<ng-template #saveElementsTemplate>
  <app-save-elements #saveElementsComponent
                     [(inspectedElement)]="mirroringContainerComponent.inspectedElement"
                     [(element)]="element"
                     [(elements)]="elements"
                     [(elementForm)]="ElementForm"
                     [(selectElement)]="selectedElement1"
                     [versionId]="data?.workspaceVersionId"
                     [uiId]="uiId"
                     [isEdit]="isEdit"
                     (handleElementUpdateEventEmitter)="handleElementUpdate($event.err,$event.isCreate)">
  </app-save-elements>
</ng-template>
