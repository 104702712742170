<form
  class="ts-form p-30 mt-n80"
  [formGroup]="stepGroupForm">
  <div
    *ngIf="stepGroups?.content?.length || searchQuery?.length"
    class="px-5 pt-14 pb-15 step-container-section"
    [style]="'padding-left: calc('+ indentation*3 +'px + 5px) !important' ">
    <app-auto-complete
      class="pb-2 ts-col-100 d-block mt-n4"
      [formGroup]="stepGroupForm"
      [formCtrlName]="stepGroupForm.controls['stepGroupId']"
      [value]="testStep?.stepGroup"
      [items]="stepGroups"
      [inline]="true"
      (onSearch)="fetchStepGroups($event)"
      (onValueChange)="setStepGroup($event)"
    ></app-auto-complete>
  </div>
  <div
    class="align-items-center d-flex"
    *ngIf="(stepGroups?.content?.length || searchQuery?.length) && testStep?.stepGroup"
  >
    <div class="text-secondary text-truncate d-flex flex-col">
      <div class="rb-semi-medium">{{'test_case.create_help.description.label' | translate}} : </div>
      <div
        #stepGroupDescElem
        class="ml-5 text-truncate"
        [textContent]="stepGroupDescriptionText || ('results.list_view.no_description' | translate)"
        [matTooltip]="stepGroupDescElem.offsetWidth < stepGroupDescElem.scrollWidth? stepGroupDescElem.textContent:''"
      ></div>
    </div>
    <a
      class="action-icon fa-external-link-alt-solid"
      target="_blank"
      [routerLink]="['/td', 'cases', testStep.stepGroup.id, 'steps']"
      [matTooltip]="'test_step.step_group.view_details' | translate"
    ></a>
  </div>
  <div
    *ngIf="stepGroups?.content?.length || searchQuery?.length"
    class="action-cf-btn-container">
    <i class="fa-help text-t-secondary pr-7"></i>
    <a
      class=" text-t-secondary"
      rel="noreferrer nofollow"
      href="https://testsigma.com/docs/test-cases/step-types/step-group/"
      target="_blank"
      [translate]="'step.create.help.note'"></a>

    <div class="ml-auto">
      <button
        (click)="cancel()"
        class="theme-btn-clear-default"
        [translate]="'btn.common.cancel'"></button>
      <button
        *ngIf="!testStep.id"
        [isLoading]="saving"
        [message]="'message.common.saving'"
        appAsyncBtn
        (click)="save()"
        class="theme-btn-primary"
        [textContent]="'btn.common.create' | translate"></button>
      <button
        *ngIf="testStep.id"
        (click)="update()"
        [isLoading]="saving"
        [message]="'message.common.updating'"
        appAsyncBtn
        class="theme-btn-primary"
        [textContent]="'btn.common.update' | translate"></button>
    </div>
  </div>
  <div
    *ngIf="!stepGroups?.content?.length && !searchQuery && !isFetching"
    class="result-status-text-2 pl-5 bg-white ts-col-100-15 pt-14">
    <i class="fa-warning px-7  bg-white"></i>
    <span
      class="bg-white"
      [translate]="'test_step.re_usable.none'"></span>
    <a
      [routerLink]="['/td', version?.id,  'step_groups', 'create']"
      [queryParams]="{isGroup: true}"
      target="_blank"
      class="result-status-text-0 px-2  bg-white"
      [translate]="'message.common.click_here'"></a>
    <span [translate]="'test_step.re_usable_none.msg'"></span>
    <a
      [routerLink]="['/td', version?.id, 'step_groups']"
      target="_blank"
      class="result-status-text-0 px-2  bg-white"
      [translate]="'message.common.click_here'"></a>
    <span
      class="bg-white"
      [translate]="'test_step.re_usable.none_msg'"></span>
  </div>
</form>
