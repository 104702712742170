<div class="details-page-container">
  <div class="ts-col-100 d-flex-wrap">
    <app-placeholder-loader  class="ts-col-100" *ngIf="!isExecutionFetchingCompleted" [isDetails]="true"></app-placeholder-loader>
    <div
      class="details-page-header">
      <div class="theme-details-header pb-30 mb-6 text-break">
        <app-td-overlay-menu-button
          [versionId]="testPlanResult?.testPlan?.workspaceVersionId"></app-td-overlay-menu-button>
        <a class="go-back-icon text-decoration-none ml-10"
           *ngIf="testPlanResult"
           [matTooltip]="'hint.message.common.back' | translate"
           [routerLink]="['/td', testPlanResult?.testPlan?.workspaceVersionId, 'results']"></a>
        <a
          class="title-name text-decoration-none"
          [routerLink]="['/td', 'plans', testPlanResult?.testPlan?.id]"
          [matTooltip]="testPlanResult?.testPlan?.name"
          [textContent]="testPlanResult?.testPlan?.name"></a>
      </div>
    </div>
    <div class="details-container no-wrap ts-col-100">
      <div
        *ngIf="testPlanResult?.environment?.name"
        class="details-items pl-30 mw-25 text-truncate">
        <label
          class="details-title"
          [translate]="'runs.details.environment'"></label>
        <div
          class="details-info text-truncate"
          [matTooltip]="testPlanResult?.environment?.name"
          [textContent]="testPlanResult?.environment?.name"></div>
      </div>
      <div class="details-items pl-30 text-nowrap">
        <label
          class="details-title"
          [translate]="'test_plan.type'"></label>
        <span [ngSwitch]="testPlanResult?.testPlan?.testPlanType==getCrossBrowser() && testPlanResult?.testPlan?.workspaceVersion.workspace.isMobile">
            <div class="details-info pt-2" *ngSwitchCase="true"
                 [translate]="'execution.test_plan_type.CROSS_DEVICE'"></div>
            <div class="details-info pt-2"  *ngSwitchCase="false"
                 [translate]="'execution.test_plan_type.'+testPlanResult?.testPlan?.testPlanType"></div>
        </span>
      </div>
      <div class="details-items pl-30 mw-75">
        <label
          class="details-title"
          [translate]="'message.common.msg'"></label>
        <div
          [matTooltip]="testPlanResult?.message"
          class="details-info pt-2 text-truncate"
             [textContent]="testPlanResult?.message"></div>
      </div>
      <app-xray-input
        [entityId] ="this.testPlanResult?.testPlanId"
        [entityType]="planEntityType"
        [display]="true">
      </app-xray-input>
    </div>
  </div>
</div>
<div class="theme-border-t d-flex theme-details-scroll-content">
  <div class="theme-border-r ts-col-25-1">
    <app-run-list
      *ngIf="testPlanResult"
      [runId]="testPlanResult.id"
      (onExecutionResult)="testPlanResult = $event"
      [testPlanResult]="testPlanResult"
      [testPlan]="testPlanResult.testPlan"></app-run-list>
  </div>
  <div class="ts-col-75">
    <app-test-case-results
      class="d-flex h-100"
      (toggleViewAction)="toggleView($event)"
      (toggleFilterAction)="toggleFilter($event)"
      (toggleDetailsAction)="toggleDetails($event)"
      (autoRefreshIntervalAction)="changeAutoRefreshTime($event)"
      (toggleAutoRefreshAction)="toggleAutoRefresh($event)"
      *ngIf="testPlanResult && showTestCaseResults"
      [showFilter]="showFilter"
      [showRunDetails]="showRunDetails"
      [isExecutionRunning]="isExecutionRunning"
      [testPlanResult]="testPlanResult"></app-test-case-results>
    <app-test-suite-results
      class="d-flex h-100"
      [showFilter]="showFilter"
      [showRunDetails]="showRunDetails"
      (toggleViewAction)="toggleView($event)"
      (autoRefreshIntervalAction)="changeAutoRefreshTime($event)"
      (toggleAutoRefreshAction)="toggleAutoRefresh($event)"
      *ngIf="testPlanResult && showTestSuiteResults"
      [isExecutionRunning]="isExecutionRunning"
      [testPlanResult]="testPlanResult"></app-test-suite-results>
    <app-test-machine-results
      class="d-flex h-100"
      [showFilter]="showFilter"
      [showRunDetails]="showRunDetails"
      (toggleViewAction)="toggleView($event)"
      (autoRefreshIntervalAction)="changeAutoRefreshTime($event)"
      (toggleAutoRefreshAction)="toggleAutoRefresh($event)"
      *ngIf="testPlanResult && showTestMachineResults"
      [isExecutionRunning]="isExecutionRunning"
      [testPlanResult]="testPlanResult"></app-test-machine-results>
  </div>
</div>
