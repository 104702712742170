<div class="theme-section-header theme-border-y theme-border-l" style="padding: 18px 9px 18px 20px;">
  <div
    class="section-title"
    [translate]="'test_machine.details.quick_info.header' | translate"></div>
  <div class="ml-auto">
    <i
      class="fa-close-alt  action-hover-icon"
      (click)="toggleDetails(true)"
      [matTooltip]="'btn.common.close' | translate"></i>
  </div>
</div>
<div class="theme-quick-container">
  <div class="d-flex" *ngIf="showList == 'TCR'">

    <div class="highChart">
      <app-result-donut-chart
        [width]="118"
        [height]="118"
        [resultEntity]="environmentResult"></app-result-donut-chart>
    </div>

    <div class="legend-container ml-14 w-100 text-right">
      <div class="legend-item" (click)="filter(resultConstant.SUCCESS)">
        <i
          class="result-status-0 fa-show legend-icon btn">
        </i>
        <span [textContent]="('execution.result.SUCCESS' | translate) +
        ' : '+ environmentResult.passedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.FAILURE)">
        <i
          class="result-status-1 fa-show legend-icon btn result-status-active-1">
        </i>
        <span [textContent]="('execution.result.FAILURE' | translate) +
        ' : '+ environmentResult.failedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.ABORTED)">
        <i
          class="result-status-2 fa-show legend-icon btn result-status-active-2">
        </i>
        <span [textContent]="('execution.result.ABORTED' | translate) +
        ' : '+ environmentResult.abortedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.NOT_EXECUTED)">
        <i
          class="result-status-3 fa-show legend-icon btn result-status-active-3">
        </i>
        <span [textContent]="('execution.result.NOT_EXECUTED' | translate) +
        ' : '+ environmentResult.notExecutedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.QUEUED)">
        <i
          class="result-status-5 fa-show legend-icon btn result-status-active-5">
        </i>
        <span [textContent]="('execution.result.QUEUED' | translate) +
        ' : '+ environmentResult.queuedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.STOPPED)">
        <i
          class="result-status-6 fa-show legend-icon btn result-status-active-6">
        </i>
        <span [textContent]="('execution.result.STOPPED' | translate) +
        ' : '+ environmentResult.stoppedCount+''"></span>
      </div>

      <div class="legend-item theme-border-t mt-5">
        <span class="white-space-nowrap" [textContent]="('execution.result.test_case.total' | translate) +
        ' : '+ environmentResult.totalCount+''"></span>
      </div>
    </div>

  </div>

  <div class="d-flex flex-column" *ngIf="showList == 'TSR' && results">
    <div
      class="highChart ts-col-100 d-flex"
      id="run-details-bar"
      style="height: 150px">
      <app-run-details-bar-chart
        class="ts-col-100 d-flex"
        [id]="'run-details-bar'"
        [results]="results"></app-run-details-bar-chart>
    </div>
    <div class="legend-container mt-30 w-100 text-right">
      <div class="legend-item" (click)="filter(resultConstant.SUCCESS)">
        <i
          class="result-status-0 fa-show legend-icon btn">
        </i>
        <span [textContent]="('execution.result.SUCCESS' | translate) +
        ' : '+ passedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.FAILURE)">
        <i
          class="result-status-1 fa-show legend-icon btn result-status-active-1">
        </i>
        <span [textContent]="('execution.result.FAILURE' | translate) +
        ' : '+ failedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.ABORTED)">
        <i
          class="result-status-2 fa-show legend-icon btn result-status-active-2">
        </i>
        <span [textContent]="('execution.result.ABORTED' | translate) +
        ' : '+ abortedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.NOT_EXECUTED)">
        <i
          class="result-status-3 fa-show legend-icon btn result-status-active-3">
        </i>
        <span [textContent]="('execution.result.NOT_EXECUTED' | translate) +
        ' : '+ notExecutedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.QUEUED)">
        <i
          class="result-status-5 fa-show legend-icon btn result-status-active-5">
        </i>
        <span [textContent]="('execution.result.QUEUED' | translate) +
        ' : '+ queuedCount+''"></span>
      </div>
      <div class="legend-item" (click)="filter(resultConstant.STOPPED)">
        <i
          class="result-status-6 fa-show legend-icon btn result-status-active-6">
        </i>
        <span [textContent]="('execution.result.STOPPED' | translate) +
        ' : '+ stoppedCount+''"></span>
      </div>
      <div class="legend-item theme-border-t mt-5">
        <span [textContent]="((showList == 'TSR'? 'execution.result.test_suite.total':'execution.result.test_machine.total')| translate) +
        ' : '+ totalCount+''"></span>
      </div>
    </div>
  </div>

  <div class="details-container sm value-sm flex-column align-items-baseline pt-30">

    <div class="details-items">
      <label
        class="details-title"
        [translate]="'result.start_time'"></label>
      <div class="details-info d-flex">
        <span
          [textContent]="environmentResult.startTime  | date:'h:mm a MMM d, y'"></span>
        <span class="d-flex pl-7">
          <span [textContent]="'( '"></span>
          <app-duration-format [duration]="environmentResult.duration"></app-duration-format>
          <span [textContent]="' )'"></span>
        </span>
      </div>
    </div>

    <div class="details-items text-break" *ngIf="environmentResult.environmentId">
      <label
        class="details-title"
        [translate]="'runs.details.environment'"></label>
      <div class="details-info"
           [textContent]="environmentResult?.testPlanResult?.environment?.name || '-'"></div>
    </div>

    <div class="details-items">
      <label
        class="details-title"
        [translate]="'message.common.msg'"></label>
      <div class="details-info text-wrap"
           [textContent]="environmentResult?.message"></div>
    </div>
  </div>
</div>
