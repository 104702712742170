<div class="w-100 h-100">
  <div class="theme-overlay-container">
    <div class="theme-overlay-header">
      <span class="theme-overlay-title" [translate]="'settings.auth.google_oauth'"></span>
      <button class="theme-overlay-close" type="button"
              [matTooltip]="'hint.message.common.close' | translate"
              mat-dialog-close>
      </button>
    </div>

    <div class="theme-overlay-content">
      <form class="ts-form pt-30">
        <div class="form-group ts-form">

          <div
            class="form-group ts-col-100 combined-control p-0">
            <div class="p-0 field mb-0">
              <input
                type="text"
                id="googleClientId"
                name="googleClientId"
                [value]="authConfig.googleClientId"
                [(ngModel)]="this.authConfig.googleClientId"
                class="form-control"/>
              <label
                [translate]="'settings.auth.google.client_id'"
                for="googleClientId"></label>
            </div>
            <button
              (click)="showCopied('googleClientId')"
              [cdkCopyToClipboard]="authConfig?.googleClientId"
              [translate]="coping?.['googleClientId'] ? 'uploads.list.message.copied' :'btn.common.copy'"
              class="theme-btn-clear-default"></button>
          </div>
        </div>
      </form>
    </div>

    <div class="theme-overlay-footer">
      <button
        type="button"
        mat-dialog-close
        class="theme-btn-clear-default" [translate]="'btn.common.cancel'">
      </button>
      <div class="ml-10 d-inline">
        <button
          type="submit"
          appAsyncBtn
          [isLoading]="saving"
          [message]="'message.common.updating'"
          [textContent]="'btn.common.update' | translate"
          class="theme-btn-primary" (click)="update()">
        </button>
      </div>
    </div>

  </div>
</div>
