<button type="button"
        class="btn theme-border-i-d theme-text p-0 d-inline-flex mt-n2 ml-14">
  <a
    *ngIf="previousResult"
    [routerLink]="previousResultRoute"
    [style.pointer-events]="!!!previousResult ?'none':'auto'"
    (click)="selectList(previousResult?.stepResultScreenshotComparison?.id)"
    [matTooltip]="'hint.message.common.previous.item' | translate"
    class="fa-left-arrow-thin px-7 py-6  theme-border-r text-t-highlight text-decoration-none"></a>
  <a
    *ngIf="!!!previousResult"
    class="fa-left-arrow-thin px-7 py-6  theme-border-r text-decoration-none not-allowed text-muted"></a>
  <a
    [matMenuTriggerFor]="menuTrigger"
    [matTooltip]="'visual_test.hint.test_steps_list' | translate"
    class="fa-list px-7 py-6  theme-border-r"></a>
  <a
    *ngIf="nextResult"
    [routerLink]="nextResultRoute"
    [matTooltip]="'hint.message.common.next.item' | translate"
    (click)="selectList(nextResult?.stepResultScreenshotComparison?.id)"
    class="fa-right-arrow-thin px-7 py-6  text-decoration-none text-t-highlight"></a>
  <a
    *ngIf="!!!nextResult"
    class="fa-right-arrow-thin px-7 py-6  text-muted not-allowed text-decoration-none"></a>
</button>
<div>
  <mat-menu
    backdropClass="theme-mat-menu-center"
    panelClass="my-panel-class"
    #menuTrigger="matMenu" class="list-container theme-mat-menu">
    <a
      [class.active]="testStepResult?.stepResultScreenshotComparison?.id == currentComparison"
      class="list-view sm-pm align-items-center"
      [ngClass]="{'cursor-default':!testStepResult?.stepResultScreenshotComparison,'pointer green-highlight':testStepResult?.stepResultScreenshotComparison}"
      *ngFor="let testStepResult of filteredTestStepResult?.content"
      (click)="testStepResult?.stepResultScreenshotComparison && selectList(testStepResult?.stepResultScreenshotComparison?.id)"
    >
      <i
        *ngIf="testStepResult?.stepResultScreenshotComparison"
        [matTooltip]="(testStepResult.isVisualFailed ? 'visual_test.hint.differences' : 'visual_test.hint.no_differences') | translate"
        class="fa-camera ml-10 fz-18"
        [class.result-status-text-1]="testStepResult.isVisualFailed"
        [class.result-status-text-0]="!testStepResult.isVisualFailed">
      </i>
      <div
        class="d-flex flex-wrap ml-auto ts-col-100-35">
        <div class="ts-col-100">
          <span *ngIf="testStepResult.template" [innerHTML]="testStepResult.parsedStep"></span>
          <span *ngIf="testStepResult.isStepGroup" [textContent]="testStepResult.testComponent?.name"></span>
          <span *ngIf="testStepResult.isCustomFunction || testStepResult.isRestStep || !testStepResult.template"
                [textContent]="testStepResult?.stepDetail?.action"></span>
        </div>
      </div>
    </a>
  </mat-menu>
</div>

