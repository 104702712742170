/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */
export enum ApplicationPathType {
  USE_PATH = "USE_PATH",
  APP_DETAILS = "APP_DETAILS",
  UPLOADS = "UPLOADS"
}
