<div class="import-form-component theme-overlay-container sm-pm-content-px-0 add-test-suite">
  <div class="theme-overlay-header">
    <div class="d-flex h-100 align-items-center">
      <div
        class="theme-overlay-title px-16 h-100 d-flex align-items-center"
        (click)="activeTab = TESTSIGMA_IMPORT"
        [ngClass]="activeTab== TESTSIGMA_IMPORT ? 'border-active-b-4' : 'border-white-b-4' "
        [translate]="'import.form.title'">
      </div>
      <div *ngIf="hasTestProject"
        class="theme-overlay-title px-16 h-100 d-flex align-items-center"
        (click)="activeTab = TEST_PROJECT_IMPORT"
        [ngClass]="activeTab== TEST_PROJECT_IMPORT ? 'border-active-b-4' : 'border-white-b-4' "
        [translate]="'import.test_project.form.title'"
      ></div>
    </div>
    <button
      class="theme-overlay-close mr-16"
      type="button"
      [matTooltip]="'hint.message.common.close' | translate"
      mat-dialog-close>
    </button>
  </div>
  <div *ngIf="activeTab== TESTSIGMA_IMPORT" class="theme-overlay-content theme-border-y">
    <div class="ts-form h-100">
      <form class="h-100">
        <div class="d-flex align-items-center h-100">
          <div *ngIf="!(this.option.filterId>0)" class="ts-col-35 pr-30 h-100">
            <div class="d-flex">
              <div class="align-items-baseline d-flex form-group pt-5 pb-0">
                <label class="mt-20 mb-30 theme-btn-clear-default overflow-x-hidden" *ngIf="!fileName" @fade>
                  <i class="fa-pin pr-5"></i> Choose File
                  <input class="d-none" type="file" required
                         [accept]=" '.zip'"
                         (change)="uploadedFile($event)">
                </label>
              </div>
              <div class="overflow-x-hidden mt-10 mb-30 p-16 pt-0 border-rds-2 f-medium d-inline-flex align-items-center
                    bg-grey-x-light mw-100 w-100"
                   *ngIf="this.fileName">
                  <span [textContent]="this.fileName"
                        class="pr-10 text-truncate"></span>
                <i class="fa-trash-thin pointer ml-auto" (click)="removeUpload()"></i>
              </div>
            </div>
            <div class="form-group">
              <app-auto-complete
                *ngIf="applications"
                class="pb-2 d-block"
                [items]="applications"
                [formGroup]="workspaceSwitcherForm"
                [hasApplicationIcon]="true"
                [formCtrlName]="workspaceSwitcherForm?.controls['application']"
                [value]="selectedWorkspace"
                (onSearch)="fetchApplications($event)"
                (onValueChange)="setSelectedApplication($event)"
              ></app-auto-complete>
              <label [translate]="'project_switcher.select_workspace'" class="control-label"></label>
            </div>
            <div class="form-group mb-5">
              <app-auto-complete
                *ngIf="versions"
                class="pb-2 d-block"
                [items]="versions"
                [formGroup]="workspaceSwitcherForm"
                [formCtrlName]="workspaceSwitcherForm?.controls['version']"
                [value]="selectedVersion"
                (onSearch)="fetchVersions($event)"
                (onValueChange)="setSelectedVersion($event)"
              ></app-auto-complete>
              <label [translate]="'project_switcher.select_version'" class="control-label"></label>
            </div>
            <div class="mt-10">
              <div
                class="pl-5 rb-bold pointer"
                [translate]="'imports.exists.action'"></div>

              <div class="d-inline-flex mt-5">
                <div
                  class="d-flex align-items-center pr-20">
                  <input
                    type="radio"
                    id="skipAction"
                    name="skipAction"
                    [value]="true"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="skipEntityExists"
                  />
                  <label
                    for="skipAction"
                    class="pl-5 pointer"
                    [translate]="'imports.skip.entity.exists'"></label>
                </div>

                <div
                  class="d-flex align-items-center pl-25">
                  <input
                    type="radio"
                    id="overwirte"
                    name="skipAction"
                    [value]="false"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="skipEntityExists"

                  />
                  <label
                    for="skipAction"
                    class="pl-5 pointer"
                    [translate]="'imports.skip.entity.overwrite'"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="border-brand-l-1 h-100" *ngIf="!(this.option.filterId>0)"></div>
          <div class="ts-col-65 pt-30 h-100">
            <div class="d-flex">
              <div class="h-30p pl-30 rb-light">
                <i class="fa-info"></i>
                <span class="pl-8" [translate]="'import.model.info'"></span>
              </div>
            </div>
            <div class="d-flex" [formGroup]="importForm">
              <div class="ts-col-50 pl-30">
                <div class="py-5">
                  <mat-checkbox class="mat-checkbox" [formControlName]="['isTestDataEnabled']"
                                (change)="checkDependencies($event, 'isTestDataEnabled')">
                    <span [translate]="'backup.model.test_data'" class="rb-medium"></span>
                  </mat-checkbox>
                </div>
                <div class="py-5">
                  <mat-checkbox [formControlName]="['isElementEnabled']" class="mat-checkbox"
                                (change)="checkDependencies($event, 'isElementEnabled')">
                    <span [translate]="'backup.model.elements'" class="rb-medium"></span>
                  </mat-checkbox>
                </div>
                <div class="py-5">
                  <mat-checkbox [formControlName]="['isUploadsEnabled']" class="mat-checkbox"
                                (change)="checkDependencies($event, 'isUploadsEnabled')">
                    <span [translate]="'backup.model.uploads'" class="rb-medium"></span></mat-checkbox>
                </div>
                <div class="py-5">
                  <mat-checkbox [formControlName]="['isTestCaseEnabled']" class="mat-checkbox"
                                (change)="checkDependencies($event, 'isTestCaseEnabled')">
                    <span [translate]="'backup.model.testcase'" class="rb-medium"></span></mat-checkbox>
                </div>
                <div class="py-5">
                  <mat-checkbox [formControlName]="['isTestStepEnabled']" class="mat-checkbox"
                                (change)="checkDependencies($event, 'isTestStepEnabled')">
                    <span [translate]="'backup.model.teststep'" class="rb-medium"></span></mat-checkbox>
                </div>
              </div>
              <div class="ts-col-50">
                <div class="py-5">
                  <mat-checkbox [formControlName]="['isTestCaseTypeEnabled']" class="mat-checkbox"
                                (change)="checkDependencies($event, 'isTestCaseTypeEnabled')">
                    <span [translate]="'backup.model.test_case_types'" class="rb-medium"></span></mat-checkbox>
                </div>
                <div class="py-5">
                  <mat-checkbox [formControlName]="['isTestCasePriorityEnabled']" class="mat-checkbox"
                                (change)="checkDependencies($event, 'isTestCasePriorityEnabled')">
                    <span [translate]="'backup.model.test_case_priorities'" class="rb-medium"></span></mat-checkbox>
                </div>
                <div class="py-5">
                  <mat-checkbox [formControlName]="['isSuitesEnabled']" class="mat-checkbox"
                                (change)="checkDependencies($event, 'isSuitesEnabled')">
                    <span [translate]="'backup.model.test_suites'" class="rb-medium"></span></mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="activeTab==TEST_PROJECT_IMPORT"
       class="theme-overlay-content theme-border-y">
    <app-test-project-import
    (onImportClick)="changeSavingStatus($event)">
    </app-test-project-import>
  </div>
  <div class="theme-overlay-footer">
    <button
      [translate]="'btn.common.cancel'"
      class="theme-btn-clear-default" mat-dialog-close></button>
    <button
      (click)="import()"
      [disabled]="isSaving"
      class="theme-btn-primary"
      type="button">
      {{isSaving ? 'Please wait...' : 'Import'}}
    </button>
  </div>
</div>
