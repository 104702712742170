<div class="theme-overlay-container">
  <div class="theme-overlay-header">
    <div
      class="theme-overlay-title">
      <i class="fa-warning text-danger fz-20"></i>
      <span [translate]="'element.duplicate_element_warning.title'" class="pl-8 fz-16 rb-medium"></span>
    </div>
    <button
      [mat-dialog-close]="undefined"
      [matTooltip]="'hint.message.common.close' | translate"
      class="theme-overlay-close"
      type="button">
    </button>
  </div>

  <div class="theme-overlay-content confirmation-model">
    <div
      [innerHTML]="modalData.description"
      class="text-t-secondary pb-15 lh-2"></div>
    <div class="list-content overflow-x-hidden  theme-only-items-scroll">
      <cdk-virtual-scroll-viewport
        class="list-container virtual-scroll-viewport"
        itemSize="27"
        style="max-height:261px;height:261px">
        <div
          *cdkVirtualFor="let element of modalData.elements ; let index=index; let first=first"
          [class.border-separator-t-1]="first"
          class="list-view md-pm green-highlight">
          <div [textContent]="element.name" class="ts-col-50 text-truncate pr-20"></div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>

  <div class="theme-overlay-footer">
    <button
      [mat-dialog-close]="false"
      [translate]="modalData?.isRecorder?'element.use_existing_element' : 'btn.common.cancel'"
      class="theme-btn-primary"></button>
    <button
      [mat-dialog-close]="true"
      [translate]="modalData?.isUpdate?'btn.common.yes_update':'btn.common.yes_create'"
      class="border-0 p-8 pl-12 pr-12 btn btn-delete text-white theme-btn-clear-default"></button>
  </div>
</div>
