<div class="theme-overlay-container">
  <div class="theme-overlay-header">
    <div
      class="theme-overlay-title"
      [translate]="'test_plan.environment.'+( workspace.isWeb ? 'machine' : 'device' )+ ( isEdit ? '.edit.title' : '.title')">
    </div>
    <button
      class="close"
      type="button"
      [matTooltip]="'hint.message.common.close' | translate"
      mat-dialog-close>
    </button>
  </div>
  <div class="theme-overlay-content">
    <div *ngIf="activeEnvironmentFormGroup" class="ts-form pt-30">
<!--      <div-->
<!--        class="pb-30 d-flex justify-content-end"-->
<!--        *ngIf="version?.workspace?.isWeb && advancedSettings && activeExecutionEnvironment"-->
<!--        [formGroup]="activeEnvironmentFormGroup">-->
<!--        <mat-checkbox class="mat-checkbox" id="runInParallel" [formControlName]="['runInParallel']">-->
<!--          <span [translate]="'test_plan.environment.select_suite.run_in_parallel'"></span></mat-checkbox>-->
<!--      </div>-->
      <div
        class="pb-30 d-flex justify-content-end"
        *ngIf="version?.workspace?.isWeb && advancedSettings && activeExecutionEnvironment"
        [formGroup]="activeEnvironmentFormGroup">
        <mat-checkbox class="mat-checkbox" id="createSessionAtCaseLevel" [formControlName]="['createSessionAtCaseLevel']">
          <span [translate]="'test_plan.environment.select_suite.run_at_testcase_level'"></span></mat-checkbox>
      </div>
      <div class="form-group pb-20 ml-auto ts-col-100" [formGroup]="activeEnvironmentFormGroup" >
        <label class="required fz-14 rb-medium" [translate]="'message.common.label.name'"></label>
        <input
          class="form-control mt-10" required name="title" formControlName="title" autofocus
          [value]="activeEnvironmentFormGroup?.value?.title"
          (input)="updateTitle($event)"
          [minlength]="4"
          [maxlength]="120"
        />

        <div class="error left"
             *ngIf="activeEnvironmentFormGroup?.controls?.title?.errors?.minlength"
             [textContent]="'form.validation.common.min_length' | translate: {FieldName:'Name' , min:'4'}"></div>
        <div class="error left"
             *ngIf="activeEnvironmentFormGroup?.controls?.title?.errors?.maxLength"
             [textContent]="'form.validation.common.max_length' | translate: {FieldName:'Name' , max:'120'}"></div>
      </div>
      <app-test-plan-lab-type-dropdown
        (onVersionSelect)="setEnvironmentVersion($event)"
        (onPreRequisiteSelect)="setEnvironmentPreRequisite($event)"
        [execution]="testPlan"
        [executionType]="activeEnvironmentFormGroup.controls['testPlanLabType']?.value"
        [executionEnvironments]="testPlan.testDevices"
        [version]="activeExecutionEnvironment.version"
        [activeExecutionEnvIndex]="activeExecutionEnvIndex"
        [formGroup]="activeEnvironmentFormGroup"></app-test-plan-lab-type-dropdown>
      <app-test-plan-test-machine-form
        class="column"
        *ngIf="version?.workspace?.isWeb && activeEnvironmentFormGroup"
        [testPlanLabType]="testLabType"
        [formGroup]="activeEnvironmentFormGroup"
        [isAvailableCheck]="false"
        [version]="version"
        [isEdit]="isEdit"></app-test-plan-test-machine-form>
      <app-test-plan-device-form
        class="column"
        *ngIf="version?.workspace?.isMobile && activeEnvironmentFormGroup"
        [formGroup]="activeEnvironmentFormGroup"
        [version]="version"
        [isEdit]="isEdit"
        [testPlanLabType]="testLabType"></app-test-plan-device-form>
      <app-test-plan-app-uploads-form
        *ngIf="version?.workspace?.isMobileNative && environmentControl"
        [formGroup] = "activeEnvironmentFormGroup"
        [version]="version"
        [isEdit]="isEdit"
        [testPlanLabType]="testLabType"></app-test-plan-app-uploads-form>
      <app-desired-capabilities
        *ngIf="environmentControl"
        [capabilities]="activeExecutionEnvironment?.capabilities"
        [formGroup]="activeEnvironmentFormGroup"></app-desired-capabilities>

      <div
        (cdkDropListDropped)="drop($event)"
        *ngIf="advancedSettings && activeExecutionEnvironment"
        [cdkDropListData]="activeExecutionEnvironment?.testSuites" cdkDropList
        class="form-group border-rds-4 list-container">
        <div
          [innerHTML]="'test_plan.environment.select_suite.title'| translate"
          class="fz-14 rb-medium pb-10"
          [class.theme-border-b]="activeExecutionEnvironment?.testSuites?.length"></div>
        <div
          *ngFor="let suite of activeExecutionEnvironment?.testSuites; let index = index"
          [cdkDragData]="suite"
          cdkDrag
          cdkDragBoundary=".cdk-drop-list.drag-list"
          cdkDragLockAxis="y"
          class="p-10 theme-border-b align-items-center d-flex green-highlight list-view">
          <i class="fa-drag-n-drop mr-10"></i>
          <span [textContent]="index+1" class="mr-10"></span>
          <span [textContent]="suite.name" class="text-truncate mw-80 py-7"></span>
          <i *ngIf="originalExecutionEnvironment?.testSuites?.length>1"
             (click)="removeSuite(suite)"
             class="action-icons action-hover-icon fa-trash-thin pointer ml-auto"></i>
        </div>
        <div class="d-flex justify-content-end w-fit-content mr-60 position-relative">
          <button
            (click)="addSuites()"
            [textContent]="'+ '+('test_plan.environment.select_suite.add' | translate)"
            class="theme-btn-clear-default mt-20 mr-60"></button>
          <div
            *ngIf="!originalExecutionEnvironment?.testSuites?.length && submitted"
            [translate]="'test_plan.environment.test_suites.required'"
            class="error left mt-10"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="theme-overlay-footer">
    <button
      [translate]="'btn.common.cancel'"
      class="theme-btn-clear-default"
      mat-dialog-close></button>
    <button
      [disabled]="activeEnvironmentFormGroup?.invalid"
      (click)="saveEnvironment()"
      [translate]=" isEdit ? 'btn.common.update' : 'btn.common.create' "
      class="btn ml-14 theme-btn-primary"></button>
  </div>
</div>
