/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

export enum TestPlanType {
  DISTRIBUTED = "DISTRIBUTED",
  CROSS_BROWSER = "CROSS_BROWSER"
}
