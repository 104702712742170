<div class="ts-form mt-20" *ngIf="!issueTypes">
  <app-placeholder-loader [isDetails]="true" ></app-placeholder-loader>
</div>
<form
  (ngSubmit)="onSubmit()"
  class="ts-form h-100 form-field-bg" *ngIf="issueTypes">
  <div
    class=" overflow-x-hidden p-30 theme-only-items-scroll sm-h" >
    <div class="d-flex">
      <label
        class="ts-col-50 rb-medium text-t-highlight fz-18"
        [translate]="isLinkToIssue ? 'test_case_result.details.report_link_bug.title' : 'test_case_result.details.report_create_bug.title'"
      ></label>
      <button
        type="button"
        (click)="toggleLinkToIssue()"
        [translate]="isLinkToIssue ?  'test_case_result.details.btn.create_new' : 'test_case_result.details.btn.link_to_existing'"
        class="theme-btn-clear-default ml-auto"></button>
    </div>

    <div class="form-group pb-20 d-flex flex-wrap">
      <label
        class="custom-label text-t-highlight required ts-col-100"
        [translate]="'test_case_result.details.project.title'"></label>
      <div class="dropdown section-title ts-col-50">
        <div
          [class.showField]="isProjectShow"
          class="btn-group text-nowrap field">
          <a
            (click)="toggleDropdown('isProjectShow'); isPriorityShow = false; isIssueTypeShow = false"
            class="rb-medium d-flex align-items-center theme-border-y theme-border-x p-10 border-rds-4"
            [textContent]="selectedProject.projectKey">
          </a>
        </div>
        <div
          *ngIf="isProjectShow"
          class="dropdown-menu d-block drop-down-transparent">
          <ul
            class="bg-white border-rds-4 ng-scope pl-0 shadow-all2-b4 m-0">
            <li
              class="p-10 rb-medium grey-on-hover text-dark d-flex align-items-center"
              *ngFor="let project of projects"
              (click)="setSelectedItem('selectedProject', project, 'isProjectShow')"
              [textContent]="'['+selectedProject.projectKey + ']' +project.name">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="form-group pb-20 d-flex flex-wrap">
      <label
        class="custom-label text-t-highlight required ts-col-100"
        [translate]="'test_case_result.details.issue_types'"></label>
      <div class="dropdown section-title ts-col-50">
        <div
          [class.showField]="isIssueTypeShow"
          class="btn-group text-nowrap field">
          <a
            (click)="toggleDropdown('isIssueTypeShow');isProjectShow = false; isPriorityShow = false;"
            class="rb-medium d-flex align-items-center theme-border-y theme-border-x p-10 border-rds-4"
            [textContent]="selectedIssueType.name">
          </a>
        </div>
        <div
          *ngIf="isIssueTypeShow"
          class="dropdown-menu d-block drop-down-transparent">
          <ul
            class="bg-white border-rds-4 ng-scope pl-0 shadow-all2-b4 m-0">
            <li
              class="p-10 rb-medium grey-on-hover text-dark d-flex align-items-center"
              *ngFor="let issueType of issueTypes"
              (click)="setSelectedItem('selectedIssueType', issueType, 'isIssueTypeShow')"
              [textContent]="issueType.name">
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="form-group pb-20 d-flex flex-wrap">
      <label
        class="custom-label text-t-highlight required ts-col-100"
        [translate]="'test_case_result.details.priority'"></label>
      <div class="dropdown section-title ts-col-50">
        <div *ngIf="selectedPriority"
          [class.showField]="isPriorityShow"
          class="btn-group text-nowrap field">
          <a
            (click)="toggleDropdown('isPriorityShow');isIssueTypeShow = false; isProjectShow = false"
            class="rb-medium d-flex align-items-center theme-border-y theme-border-x p-10 border-rds-4"
            [textContent]="selectedPriority.name">
          </a>
        </div>
        <div
          *ngIf="isPriorityShow && prioritiesList"
          class="dropdown-menu d-block drop-down-transparent">
          <ul
            class="bg-white border-rds-4 ng-scope pl-0 shadow-all2-b4 m-0">
            <li
              class="p-10 rb-medium grey-on-hover text-dark d-flex align-items-center"
              *ngFor="let priority of prioritiesList"
              (click)="setSelectedItem('selectedPriority', priority, 'isPriorityShow')"
              [textContent]="priority.name">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <mat-form-field
      *ngIf="isLinkToIssue"
      class="w-50 custom-mat-auto-complete">
      <input
        matInput
        #searchInput
        [placeholder]="'test_case_result.details.report_bug.search_issue_placeholder' | translate"
        aria-label="State"
        [matAutocomplete]="issues">
      <mat-autocomplete #issues="matAutocomplete">
        <mat-option *ngIf="isFetchingIssues">
          <span [translate]="'message.common.loading'"></span>
          <span class="loading-dots"></span>
        </mat-option>
        <mat-option *ngIf="!issueList.length && !isFetchingIssues">
          <span [translate]="searchInput.value ?'search.notfound':'plugins.message.no_previous_issues'"></span>
        </mat-option>
        <mat-option
          *ngFor="let issue of issueList" [value]="'['+issue.issueKey + ']'"
          (onSelectionChange)="setSelectedItem('selectedIssue', issue)">
          <span [textContent]="'['+issue.issueKey + ']' +issue.summary"></span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div
      *ngIf="!isLinkToIssue"
      [formGroup]="formFR" class="form-group pb-20">
      <label
        class="custom-label text-t-highlight required"
        for="title" [translate]="'runs.list_view.title'"></label>
      <input
        class="form-control field"
        name="title"
        [formControlName]="['summary']"
        [(ngModel)]="summary"
        id="title" type="text">
    </div>
    <div
      *ngIf="!isLinkToIssue"
      [formGroup]="formFR" class="form-group pb-20">
      <label
        class="custom-label text-t-highlight required"
        for="description" [translate]="'test_case_result.details.description'"></label>
      <textarea
        class="form-control field"
        name="description"
        [formControlName]="['description']"
        [(ngModel)]="description"
        id="description" rows="4"></textarea>
    </div>
  </div>

  <div class="form-row py-10 px-30 text-right">
    <button
      class="theme-btn-clear-default"
      mat-dialog-close
      type="button"
      [translate]="'btn.common.cancel'"></button>
    <button
      class="theme-btn-primary"
      type="submit"
      [translate]="!isLinkToIssue ? 'btn.common.save' : 'test_case_result.details.btn.link_to_existing'"
      [disabled]="(!isLinkToIssue ? !(description && summary) : !selectedIssue) || isButtonClicked"></button>
  </div>
</form>
