<div class="ts-form mt-20" *ngIf="!issueDetails">
  <app-placeholder-loader [isDetails]="true" ></app-placeholder-loader>
</div>

<div
  *ngIf="externalApplicationDetails && issueDetails"
  class="d-flex align-items-center text-t-secondary fz-15 pb-30">
  <a
    class="text-t-secondary"
    target="_blank"
    [translate]="'test_case_result.details.projects'"></a>
  <span class="px-5">/</span>
  <a
    [href]="application.url+'/'+externalApplicationDetails?.fields['fields']['System.AreaPath']"
    class="text-t-secondary d-flex"
    target="_blank">
    <span
      class="pl-5" [textContent]="externalApplicationDetails?.fields['fields']['System.AreaPath']"></span>
  </a>
  <span class="px-5">/</span>
  <a
    [href]="application.url+'/'+externalApplicationDetails?.fields['fields']['System.AreaPath']+'/_workitems/'"
    target="_blank"
    class="text-t-secondary d-flex">
    <span
      class="pl-5"
      [textContent]="externalApplicationDetails?.externalId"></span>
  </a>
  <button
    (click)="unLinkIssue(externalApplicationDetails)"
    class="ml-auto theme-btn-clear-default"
    [translate]="'test_case_result.details.btn.unlink'"></button>
</div>
<div
  class="d-flex">
  <div class="ts-col-70">
    <div
      class="fz-24 rb-medium text-t-highlight pb-20"
      [textContent]="title">
    </div>
    <div class="pb-20">
      <label
        class="rb-medium fz-14 pb-4"
        [translate]="'test_case_result.details.description'"></label>
      <div
        class="rb-medium text-t-secondary pt-7"
        [textContent]="description? htmlToPlaintext(description) : 'message.common.none' | translate ">
      </div>
    </div>
  </div>
  <div class="ts-col-30">
    <div class="pb-20">
      <div
        class="ml-7 px-10 py-6 d-inline-flex rb-medium fz-14 bg-grey-light border-rds-4 pr-50">
        <span [textContent]="issueStatus"></span>
      </div>
    </div>
    <div class="pb-20">
      <div class="rb-medium pb-4 pl-7" [translate]="'test_case_result.details.report_bug.reporter'"></div>
      <div
        class="rb-medium text-t-secondary fz-14 grey-on-hover p-7 d-flex align-items-center">
        <div
          class="img-wh lg-wh temp-user-profile">
        </div>
        <div
          class="pl-8"
          [textContent]="issueUserDetails?issueUserDetails: 'Not assigned'"></div>
      </div>
    </div>
    <div class="pb-20">
      <div
        class="rb-medium pb-4 pl-7"
        [translate]="'test_case_result.details.report_bug.issueType'"></div>
      <div
        class="rb-medium text-t-secondary fz-14 grey-on-hover p-7 d-flex align-items-center"
        [textContent]="issueTypeDetails">
      </div>
    </div>
    <div class="pb-20">
      <div
        class="rb-medium pb-4 pl-7"
        [translate]="'message.common.created_at'"></div>
      <div
        class="rb-medium text-t-secondary fz-14 grey-on-hover p-7 d-flex align-items-center"
        [textContent]="createdDate? createdDate : '--'">
      </div>
    </div>
    <div class="pb-20">
      <div
        class="rb-medium pb-4 pl-7"
        [translate]="'message.common.updated_at'"></div>
      <div
        class="rb-medium text-t-secondary fz-14 grey-on-hover p-7 d-flex align-items-center"
        [textContent]="updatedDate? updatedDate : '--'">
      </div>
    </div>
  </div>
</div>
