/*
 *
 *  * *****************************************************************************
 *  *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  *  All rights reserved.
 *  *  ****************************************************************************
 *
 */
export enum RestAuthorization {
  NONE = "NONE",
  BASIC = "BASIC",
  BEARER = "BEARER"
}
