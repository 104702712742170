<div class="theme-overlay-container">
  <div class="theme-overlay-header">
    <div
      class="theme-overlay-title"
      [translate]="'mobile_recorder.button.send_keys'">
    </div>
    <button
      class="theme-overlay-close"
      type="button"
      [matTooltip]="'hint.message.common.close' | translate"
      mat-dialog-close>
    </button>
  </div>
  <div class="theme-overlay-content py-15">

    <div class="ts-form">
      <div class="form-group">
        <label
          class="control-label"
          [translate]="'mobile_recorder.button.send_keys'"></label>
        <input type="text" class="form-control" [(ngModel)]="keys">
      </div>
    </div>
  </div>
  <div class="theme-overlay-footer">
    <button
      class="theme-btn-clear-default"
      mat-dialog-close
      [translate]="'btn.common.cancel'">
    </button>
    <button
      class="theme-btn-primary"
      [translate]="'mobile_recorder.button.send_keys'"
      [disabled]="!keys"
      [mat-dialog-close]="keys"
      [matTooltip]="((!keys)?('mobile_recorder.message.sendkeys.required'|translate ):'')">
    </button>
  </div>
</div>

