/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

export enum OnAbortedAction {
  Reuse_Session = "Reuse_Session",
  Restart_Session = "Restart_Session"
}
