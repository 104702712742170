/*
 *
 *  * *****************************************************************************
 *  *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  *  All rights reserved.
 *  *  ****************************************************************************
 *
 */

export enum TestStepPriority {
  MAJOR = "MAJOR",
  MINOR = "MINOR",
}
