export enum ScheduleType {

  // NOT_USED="NOT_USED",
  ONCE = "ONCE",
  HOURLY = "HOURLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY ="YEARLY",
  //BIWEEKLY ="BIWEEKLY",
}
