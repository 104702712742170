<div #mirroringContainer class="p-15  overflow-x-auto h-100 m-auto ts-col-100" style="background: #CEDAE6">
  <div class="d-flex h-100 inspector" [class.flex-column]="mobileRecorderEventService.isLandscapeMode">
    <div class="mirroring-container m-auto"
         [style.height.px]="(screenScaledInitially? canvasHeight:mirroringContainer.offsetHeight-30)+2"
         [style.width.px]="(screenScaledInitially? canvasWidth:mirroringContainer.offsetWidth-73)+2">
      <div class="mirror-loading-screen flex-fill" *ngIf="loading"
           [style.width.px]="mirroringContainer.offsetWidth-73"
           [style.height.px]="mirroringContainer.offsetHeight-30">
        <div class="d-flex flex-column m-auto">
          <div class="loading-logo"></div>
          <div class="pt-20">
            <span [innerHtml]="'mobile_recorder.message.preparing'|translate" class="fz-14"></span>
          </div>
        </div>
      </div>
      <div class="ts-col-100" [class.hide]="loading">
        <div *ngIf="loadingActions" class="loading-actions">
          <div>
            <mat-spinner color="accent" diameter="40"></mat-spinner>
            <span [translate]="'mobile_recorder.message.refresh'"></span>
          </div>
        </div>
        <canvas id="mobile_mirroring_canvas" class="border-rds-6 border-rds-top-5 transition-common"></canvas>
      </div>
    </div>
    <div class="inspector-tools align-items-center"
         [class.mx-auto]="mobileRecorderEventService.isLandscapeMode"
         [class.my-auto]="!mobileRecorderEventService.isLandscapeMode"
         [class.ml-auto]="!mobileRecorderEventService.isLandscapeMode"
         [class.mb-auto]="mobileRecorderEventService.isLandscapeMode"
         [class.flex-column]="!mobileRecorderEventService.isLandscapeMode"
         [class.pl-10]="!mobileRecorderEventService.isLandscapeMode"
         [class.flex-row]="mobileRecorderEventService.isLandscapeMode"
         [class.pt-20]="mobileRecorderEventService.isLandscapeMode">
      <div [class.btn-group-column]="!mobileRecorderEventService.isLandscapeMode"
           [class.btn-group-row]="mobileRecorderEventService.isLandscapeMode"
           [class.mr-15]="mobileRecorderEventService.isLandscapeMode"
           [class.mb-10]="!mobileRecorderEventService.isLandscapeMode">
        <button [class.active]="mirroring"
                [disabled]="loading"
                (click)="switchToMirroringMode()">
          <i class="fa-mirroring" [matTooltip]="'agents.mobile.inspection.switch_to_mirroring' | translate"></i>
        </button>
        <button [class.active]="!mirroring && !loading" (click)="switchToInspectMode()"
                [matTooltip]="'agents.mobile.inspection.inspection_mode' | translate"
                [disabled]="loading">
          <i class="fa-cursor-click-flash"></i>
        </button>
        <button [disabled]="mirroring || !data.recording || loading"
                [class.active]="actionType=='swipe'"
                (click)="switchToActionMode('swipe')">
          <i class="fa-swipe-action fz-20"
             [matTooltip]="'agents.mobile.inspection.swipe_action' |
               translate"></i>
        </button>
        <button [disabled]="mirroring || !data.recording || loading"
                [class.active]="actionType=='tap'"
                (click)="switchToActionMode('tap')">
          <i class="fa-tap-on-action fz-20" [matTooltip]="'agents.mobile.inspection.click_action' | translate"></i>
        </button>
      </div>

      <div [class.btn-group-column]="!mobileRecorderEventService.isLandscapeMode"
           [class.btn-group-row]="mobileRecorderEventService.isLandscapeMode"
           [class.mr-15]="mobileRecorderEventService.isLandscapeMode">
        <button [disabled]="mirroring || loading"
                (click)="navigateBack()">
          <i class="fa-back-arrow"
             [matTooltip]="'agents.mobile.inspection.back_button' |
                 translate"></i>
        </button>
        <button [disabled]="mirroring || loading"
                (click)="goToHome()">
          <i class="fa-home fz-16"
             [matTooltip]="'agents.mobile.inspection.home_button' |
                 translate"></i>
        </button>
        <button [disabled]="mirroring || loading" (click)="renderCurrentScreenshot()">
          <i class="fa-refresh-new fz-20" [matTooltip]="'agents.mobile.inspection.refresh_screenshot' |
                 translate"></i>
        </button>
      </div>

      <button [disabled]="mirroring || loading"
              (click)="changeOrientation()"
              [class.mr-15]="mobileRecorderEventService.isLandscapeMode">
        <i class="fa-screen-rotation fz-16"
           [matTooltip]="(!mobileRecorderEventService.isLandscapeMode? 'agents.mobile.inspection.change_to_landscape':'agents.mobile.inspection.change_to_portrait') |
               translate"></i>
      </button>
      <button (click)="openSearchDialog()" [disabled]="mirroring || loading"
              [class.mr-15]="mobileRecorderEventService.isLandscapeMode">
        <i class="fa-search fz-16" [matTooltip]="'agents.mobile.inspection.search_elements'|translate"></i>
      </button>

      <div [class.btn-group-column]="!mobileRecorderEventService.isLandscapeMode"
           [class.mt-10]="!mobileRecorderEventService.isLandscapeMode"
           [class.btn-group-row]="mobileRecorderEventService.isLandscapeMode" *ngIf="hasWebContexts">
        <button [class.active]="isNativeAppEnabled && !loading"
                [disabled]="loading"
                (click)="switchViewMode('NATIVE_APP')">
              <span [matTooltip]="'agents.mobile.inspection.switch_to_native' | translate">
                N
              </span>
        </button>
        <button [class.active]="!isNativeAppEnabled && !loading" (click)="switchViewMode('WEBVIEW')"
                [disabled]="loading">
              <span [matTooltip]="'agents.mobile.inspection.switch_to_webview' | translate">
                H
              </span>
        </button>
      </div>
    </div>
  </div>
</div>
