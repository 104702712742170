<button type="button"
        class="btn theme-border-i-d theme-text p-0 d-inline-flex mt-n2">
  <a
    *ngIf="previousResults?.content?.length"
    [routerLink]="['..', findFirstNonDataDrivenCase(previousResults?.content, true)]"
    [matTooltip]="'hint.message.common.previous.item' | translate"
    class="fa-left-arrow-thin px-7 py-6  theme-border-r text-decoration-none text-t-highlight"></a>
  <a
    *ngIf="!previousResults?.content?.length"
    class="fa-left-arrow-thin px-7 py-6  theme-border-r text-decoration-none not-allowed text-muted"></a>

  <a
    [matMenuTriggerFor]="menuTrigger"
    class="fa-list px-7 py-6  theme-border-r"
    [matTooltip]="'hint.message.common.list.item' | translate"
    (click)="showList()"></a>
  <a
    *ngIf="nextResults?.content?.length && nextResults?.content[0]"
    [routerLink]="['..', findFirstNonDataDrivenCase(nextResults?.content)]"
    [matTooltip]="'hint.message.common.next.item' | translate"
    class="text-t-highlight fa-right-arrow-thin px-7 py-6 text-decoration-none"></a>
  <a
    *ngIf="!nextResults?.content?.length || !nextResults?.content[0]"
    class="fa-right-arrow-thin px-7 py-6  text-muted not-allowed text-decoration-none"></a>
</button>
<!--<div #menuTrigger="matMenu">-->
  <mat-menu
    #menuTrigger="matMenu" class=" theme-mat-menu h-100">
    <cdk-virtual-scroll-viewport
      itemSize="83"
      minBufferPx="{{ 83 * 3 }}"
      maxBufferPx="{{ 83 * 6 }}"
      #testCaseResultsViewPort
      class="list-container virtual-scroll-viewport w-100 mat-menu-pagination">
      <a
        [routerLink]="['/td/test_case_results', testCaseResult?.id]"
        [routerLinkActive]="'active'"
        [class.active]="isTestCaseActive(testCaseResult)"
        class="list-view green-highlight sm-pm pointer"
        *cdkVirtualFor='let testCaseResult of testCasesList'>
        <div
          class="d-flex fz-22">
          <i
            [class.fa-result-0]="testCaseResult?.isPassed"
            [class.fa-result-1]="testCaseResult?.isFailed"
            [class.fa-result-2]="testCaseResult?.isAborted"
            [class.fa-result-3]="testCaseResult?.isNotExecuted"
            [class.fa-result-5]="testCaseResult?.isQueued"
            [class.fa-result-6]="testCaseResult?.isStopped"
            class="pl-6 mt-4"></i>
        </div>
        <div
          class="d-flex flex-wrap ml-auto text-truncate ts-col-100-35 pl-5">
          <div class="ts-col-100">
            <div
              class="list-title text-truncate"
              [matTooltip]="testCaseResult?.testCase?.name"
              [textContent]="testCaseResult?.testCase?.name"></div>
            <div
              class="text-truncate pt-4 text-t-secondary fz-12">
              <a
                class="theme-link"
                [matTooltip]="testCaseResult?.testDeviceResult?.testDevice?.title"
                [textContent]="testCaseResult?.testDeviceResult?.testDevice?.title "></a> /
              <a
                class="theme-link"
                [matTooltip]="testCaseResult?.testSuite?.name"
                [textContent]="testCaseResult?.testSuite?.name"></a>
            </div>
          </div>
          <div class="pt-10 text-t-secondary fz-12 ts-col-100 d-flex">
            <div
              class="d-flex justify-content-end text-nowrap">
              <div
                [textContent]="testCaseResult?.startTime | date:'MMM d, h:mm:ss'"></div>
              <div class="pl-8">
                <app-duration-format [duration]="testCaseResult?.duration"></app-duration-format>
              </div>
            </div>
            <div
              class="text-nowrap text-center  ml-auto text-truncate pl-5">
              <app-test-machine-info-column
                [environmentResult]="testCaseResult?.testDeviceResult"
                [testPlanResult]="testCaseResult?.testDeviceResult?.testPlanResult"
                [testDevice]="testCaseResult?.testDeviceResult?.testDevice"></app-test-machine-info-column>
            </div>
          </div>
        </div>
      </a>
    </cdk-virtual-scroll-viewport>
  </mat-menu>
<!--</div>-->
