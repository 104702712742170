<cdk-virtual-scroll-viewport
  itemSize="59"
  class="mx-n20 theme-section-container card-container virtual-scroll-viewport overflow-y">
  <div
    *cdkVirtualFor='let suiteResult of suiteResults'>
    <div
      (click)="showTestSuiteResult(suiteResult)"
      class="list-card bordered sm-pm pointer">
      <div
        class="d-flex flex-wrap ts-col-100">
        <div class="ts-col-50 d-flex flex-wrap">
          <div
            class="ts-col-100 list-title text-break"
            [textContent]="suiteResult.testSuite.name"></div>
          <div class="ts-col-100 pt-4 text-t-secondary fz-12 d-flex align-items-center">
            <div
              class="theme-breadcrumb ts-col-50">
              <div
                *ngIf="suiteResult?.environmentResult?.testDevice?.title"
                class="theme-breadcrumb-item"
                (click)="showTestMachineResult(suiteResult?.environmentResult);$event.stopImmediatePropagation();$event.stopPropagation();$event.preventDefault()"
                [matTooltip]="suiteResult?.environmentResult?.testDevice?.title"
                [textContent]="suiteResult?.environmentResult?.testDevice?.title "></div>
            </div>
            <div
              class="text-nowrap px-10 text-center ts-col-50">
              <app-test-machine-info-column
                [environmentResult]="suiteResult?.environmentResult"
                [testPlanResult]="suiteResult?.environmentResult?.testPlanResult"
                [testDevice]="suiteResult?.environmentResult?.testDevice"></app-test-machine-info-column>
            </div>
          </div>
        </div>
        <div class="ts-col-50 d-flex">
          <div class="ts-col-50 d-flex">
            <app-result-pie-chart-column
              class="ml-auto"
              [width]="24"
              [height]="24"
              [resultEntity]="suiteResult?.lastRun"></app-result-pie-chart-column>
            <div class="text-right text-t-secondary fz-12 ml-auto mr-10">
              <div [textContent]="suiteResult?.lastRun.startTime | date:'MMM d, h:mm:ss'"></div>
              <div class="pt-4">
                <app-duration-format
                  [duration]="suiteResult?.lastRun.duration"></app-duration-format>
              </div>
            </div>
          </div>
          <div class="ts-col-50 d-flex theme-border-l pl-10">
            <div class="text-t-secondary fz-12">
              <div [translate]="'re_run.previous_result'"></div>
              <app-result-status-label [resultEntity]="suiteResult"></app-result-status-label>
            </div>
            <div class="text-right text-t-secondary fz-12 ml-auto">
              <div [textContent]="suiteResult.startTime | date:'MMM d, h:mm:ss'"></div>
              <div class="pt-4">
                <app-duration-format
                  [duration]="suiteResult.duration"></app-duration-format>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
