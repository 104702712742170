<div class="page-content top-0 pt-30 pl-60 ml-5 mr-auto h-100">
  <div
    *ngIf="testPlans?.content.length"
    class="list-header flex-wrap">
    <div
      class="pr-10 d-flex ts-col-45">
      <span
        [translate]="'message.common.text.title'"
        class="pl-10"></span>
    </div>
    <div
      [translate]="'test_plan.lab_type'"
      class="ts-col-25 px-10">
    </div>
  </div>
  <cdk-virtual-scroll-viewport
    *ngIf="testPlans?.content.length"
    [class.theme-only-items-scroll]="testPlans?.totalElements"
    [class.theme-w-o-h-scroll]="!testPlans?.totalElements"
    class="list-container virtual-scroll-viewport"
    itemSize="59">
    <a
      *cdkVirtualFor="let testPlan of testPlans.content; let i = index"
      [routerLink]="['/td', 'plans',  testPlan.id]"
      class="list-view green-highlight lg-pm pl-10 text-t-secondary">
      <div class="ts-col-45 text-break d-flex pl-10">
        <div>
          <a
            [routerLink]="['/td/plans', testPlan.id]"
            [textContent]="testPlan.name"
            class="list-title"></a>
          <div
            [translate]="'message.common.automated'"
            class="pt-4 text-t-secondary"></div>
        </div>
      </div>
      <div class="ts-col-25 px-10">
        <app-lab-environments-info
          *ngIf="testPlan.testDevices"
          [testPlan]="testPlan"
          [testDevices]="testPlan.testDevices"></app-lab-environments-info>
        <div
          [textContent]="('execution.type.'+testPlan.testPlanType) | translate"
          class="pt-4 text-t-secondary"></div>
      </div>
      <div class="ts-col-25 text-center align-items-baseline ml-auto justify-content-end d-flex">
        <app-schedule-form-button
          [testPlan]="testPlan"></app-schedule-form-button>
        <app-reports-button
          (click)="$event.preventDefault();$event.stopPropagation()"
          [testPlan]="testPlan"
          class="ml-10"></app-reports-button>
        <app-run-now-button
          (onStart)="fetchTestPlans()"
          (onStop)="fetchTestPlans()"
          [testPlan]="testPlan"
          class="ml-10">
        </app-run-now-button>
      </div>
    </a>
  </cdk-virtual-scroll-viewport>
  <app-pagination *ngIf="testPlans?.totalElements" [paginationData]="testPlans"
                  [currentPage]="currentPage"
                  (paginationAction)="fetchTestPlans()"></app-pagination>
  <app-placeholder-loader
    *ngIf="!fetchingCompleted"></app-placeholder-loader>
  <div *ngIf="!testPlans?.content.length" class="h-100">
    <div
      *ngIf="fetchingCompleted"
      class="empty-full-container">
      <div class="empty-run-sm"></div>
      <div
        [translate]="'test_suite.message.no_associated_plans'"
        class="empty-text"></div>
    </div>
  </div>
</div>

