/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

export enum PreRequisiteAction {
  Abort = "Abort",
  Continue = "Continue"
}
