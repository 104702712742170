<div class="w-100 h-100">
  <div class="theme-overlay-container">
    <div class="theme-overlay-header">
      <span class="theme-overlay-title" [translate]="'settings.auth.jwt'" ></span>
      <button
        type="button"
        [matTooltip]="'hint.message.common.close' | translate"
        mat-dialog-close
        class="close">
      </button>
    </div>

    <div class="theme-overlay-content">
      <form class="ts-form pt-30" [formGroup]="updateForm">


        <div class="form-group ts-form" >
          <label for="secret" class="control-label required">
            <span [translate]="'settings.auth.jwt.secret'"></span>
          </label>
          <input type="text"
                 formControlName="secret"
                 id="secret" class="form-control " >
          <span *ngIf="!updateForm.get('secret').valid && updateForm.get('secret').touched" class="help-block"
                [translate]="'settings.auth.jwt.secret'">
          </span>
        </div>
        <div class="form-group ts-form" >
          <label for="confirmSecret" class="control-label required">
            <span [translate]="'settings.auth.jwt.confirm_secret'"></span>
          </label>
          <input type="password"
                 formControlName="confirmSecret"
                 id="confirmSecret" class="form-control "  >
          <span *ngIf="updateForm.get('confirmSecret').value !== updateForm.get('secret').value && updateForm.get('confirmSecret').touched" class="help-block"
                [translate]="'settings.auth.jwt.confirm_secret'">
          </span>
        </div>

      </form>
    </div>

    <div class="theme-overlay-footer">
      <button
        type="button"
        mat-dialog-close
        class="theme-btn-clear-default" [translate]="'btn.common.cancel'">
      </button>
      <button type="submit" [disabled]="!updateForm.valid || updateForm.get('confirmSecret').value !== updateForm.get('secret').value"
              class="theme-btn-primary" (click)="onSubmit()"
              [translate]="saving? 'message.common.saving' : 'btn.common.save'"></button>
    </div>
  </div>
</div>
