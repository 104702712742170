<div class="page-header d-flex fz-20 align-items-center">
  <div
    class="ts-col-60 text-truncate rb-medium d-flex align-items-center">
    <span
      class="px-8"
      [translate]="'environments.list.title'"></span>
  </div>

  <div class="d-flex align-items-center ml-auto"
       *ngIf="selectedEnvironments.length">
    <button (click)="openDeleteDialog(null)"
            [matTooltip]="'hint.message.common.delete_selected' | translate"
            class="btn icon-btn border-rds-2 ml-14"
            *ngIf="selectedEnvironments.length">
      <i class="fa-trash-thin"></i>
    </button>
  </div>

  <div class="align-items-center ml-auto"
       [class.d-none]="hideHeaderToolBar"
       [class.d-flex]="!hideHeaderToolBar">
    <app-toggle-search-button
      (searchAction)="search($event)"></app-toggle-search-button>
    <button
      [routerLink]="['/td', versionId, 'environments','new']"
      class="theme-btn-primary ml-14 "
      [translate]="'btn.common.create'">
    </button>
    <app-sort-by-button
      class="pr-15" *ngIf="environments?.totalElements"
      [sortByColumns]="sortByColumns"
      [sortedBy]="sortedBy"
      [direction]="direction"
      [translatePreFix]="'environments.list.sort_by.'"
      (sortAction)="sortBy($event.sortBy, $event.direction)"></app-sort-by-button>
    <div class="pl-15 theme-border-l">
      <app-pagination *ngIf="environments?.totalElements" [paginationData]="environments"
                      [currentPage]="currentPage" (paginationAction)="fetchEnvironments()"></app-pagination>
    </div>
  </div>
</div>
<div class="page-content">
  <div
    *ngIf="environments?.content.length != 0"
    class="list-header">
    <div
      class="pr-10 d-flex align-items-center">
      <mat-checkbox
        [(ngModel)]="selectAll" [ngModelOptions]="{standalone: true}"
        (change)="selectAllToggle(selectAll)"
        [checked]="selectedEnvironments.length == environments?.content?.length"
        class="mat-checkbox"></mat-checkbox>
      <span
        class="pl-15">
        <app-inline-sort
          [ascending]="sortedBy!='name'? undefined : direction == ',asc'"
          (click)="sortBy('name', ((sortedBy!='name' || direction == ',desc')?',asc':',desc'))"
          [heading]="'message.common.text.title'">
          </app-inline-sort>
      </span>
    </div>
  </div>
  <app-placeholder-loader
    *ngIf="!fetchingCompleted"></app-placeholder-loader>
  <div class="list-container">
    <div class="list-view green-highlight lg-pm pl-10 pointer align-items-center"
         *ngFor="let environment of environments?.content; let i = index">
      <div class="ts-col-85 my-auto text-truncate">
        <div class="pb-7 list-title d-flex">
          <mat-checkbox
            class="mat-checkbox"
            [(ngModel)]="environment.selected" [ngModelOptions]="{standalone: true}"
            (change)="setSelectedList(environment.id,environment.selected)"></mat-checkbox>
          <span class="pl-15" [textContent]="environment.name"
                [queryParams]="{v: versionId}"
                [routerLink]="[ '/td', 'environments', environment.id, 'details']"></span>
        </div>
      </div>
      <div class="ts-col-15 text-center action-icons my-auto">
        <a
          data-placement="bottom"
          [matTooltip]="'pagination.edit' | translate"
          href="javascript:void(0)"
          [routerLink]="[ environment.id ,'edit']"
          class="fa-pencil-on-paper action-icon"></a>
        <a
          data-placement="bottom" (click)="checkForLinkedTestPlans(environment.id)"
          [matTooltip]="'pagination.delete' | translate"
          href="javascript:void(0)"
          class="fa-trash-thin action-icon"></a>
      </div>
    </div>
  </div>
  <div *ngIf="!environments?.content.length" class="h-100">
    <div *ngIf="fetchingCompleted"
         class="empty-full-container">
      <div class="empty-run-md"></div>
      <div
        class="empty-text"
        [translate]="isFiltered ? 'message.common.search.not_found' : 'environments.list.not_created'"></div>
      <button
        *ngIf="!isFiltered"
        [routerLink]="['new']" target="_parent"
        class="theme-btn-primary">
        +
        <span [translate]="'btn.common.create'"></span>
      </button>
    </div>
  </div>
</div>
